import { theme as coreTheme } from '@chakra-ui/core';

// Let's say you want to add custom colors
const theme = Object.assign(coreTheme, {
  colors: Object.assign(coreTheme.colors, {
    blue: {
      '50': '#b3e7ff',
      '100': '#b3e7ff',
      '200': '#1ab7ff',
      '300': '#1ab7ff',
      '400': '#1ab7ff',
      '500': '#0082BE',
      '600': '#009de6',
      '700': '#007ab3',
      '800': '#005780',
      '900': '#00344d',
    },
    green: {
      '50': '#d6e8ca',
      '100': '#bbd8a6',
      '200': '#73AC4B',
      '300': '#84b95f',
      '400': '#6ba046',
      '500': '#73AC4B',
      '600': '#537c36',
      '700': '#3b5927',
      '800': '#243517',
      '900': '#0c1208',
    },
    yellow: Object.assign(coreTheme.colors.yellow, {
      '50': '#F4E0B7',
      '100': '#D69B2C',
      '200': '#D69B2C',
    }),
    disabled: '#9B9B9B',
    circleNavigationActive: '#979797',
    pink: {
      ...coreTheme.colors.pink,
      '50': '#EAD7D1',
    },
    accent: '#444C56',
    circleNavigation: '#D8D8D8',
    darkGray: '#5B5959',
    medium: '#363F46',
    base: '#F5F5F5',
    lightGray: '#dddddd',
    mediumGray: '#b0b0b0',
    red: Object.assign(coreTheme.colors.red, {
      '50': '#E36776',
    }),
    hero: {
      '50': '#f8f8f8',
      '100': '#efefef',
      '200': '#efefef',
      '300': '#efefef',
      '400': '#efefef',
      '500': '#efefef',
      '600': '#efefef',
      '700': '#efefef',
      '800': '#efefef',
      '900': '#666',
    },
    dark: '#30373F',
    darkAccent: '#273236',
    header: '#323232',
    textPrimary: '#4A4A4A',
    textSecondary: '#747474',
    primary: '#277FB2',
    secondary: '#6BA443',
    tertiary: '#00A3B0',
    drawer: '#2D3748',
  }),
  fonts: Object.assign(coreTheme.fonts, {
    body: '"OpenSans", Helvetica, Arial, sans-serif',
    mono: '"OpenSans", Helvetica, Arial, sans-serif',
    heading: '"OpenSans", Helvetica, Arial, sans-serif',
  }),
  fontSizes: Object.assign(coreTheme.fontSizes, {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '22px',
    '3xl': '24px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px',
  }),
});

export default theme;
